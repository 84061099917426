import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {FinaliseModel, ForgotPasswordModel, LoginModel, LoginSubmitModel, Register, SubscriptionDetails} from '../models/app.user.model';
import {BaseService} from './app.base.service';
import {Observable, Subject} from 'rxjs';
import jwt_decode from 'jwt-decode';
import {finalize} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppState} from '../app.state';

@Injectable()
export class AuthService extends BaseService implements OnDestroy {
    private _isTokenRefreshing: boolean = false;
    private logoutSubject = new Subject<boolean>();

    get logoutObservable() {
        return this.logoutSubject.asObservable();
    }

    constructor(public http: HttpClient, private appState: AppState) {
        super(http);
    }

    ngOnDestroy() {
        this.logoutSubject.unsubscribe();
    }

    login(loginSubmit: LoginSubmitModel) {
        return this.http.post<LoginModel>(this.environmentSettings.apiBaseUrl + '/login?username=' + loginSubmit.userid + '&password=' + loginSubmit.password, {});
    }

    refreshToken() {
        if (!this.getToken()) {
            return;
        }
        this._isTokenRefreshing = true;
        return this.http.post<LoginModel>(this.environmentSettings.apiBaseUrl + `/refresh-token`, {}, {params: {token: this.getToken()}}).pipe(
            finalize(() => {
                this._isTokenRefreshing = false;
            })
        );
    }

    getBusinessSubscriptionStatusById(userId: string) {
        return this.http.get<SubscriptionDetails>(this.environmentSettings.apiBaseUrl + `/GetBusinessSubscriptionStatusById/` + userId);
    }

    updateToken() {
        if (this._isTokenRefreshing) {
            return;
        }
        this.refreshToken().subscribe(result => {
            this.updateTokenInStorage(result);
        });
    }

    updateTokenInStorage(result: LoginModel) {
        localStorage.setItem('token', result.token);
    }

    private setSession(authResult) {
        const expiresAt = moment().add(authResult.expiresIn, 'second');

        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    }

    register(registerModel: Register) {
        registerModel.isTermOfService = true;
        return this.http.post<any>(this.environmentSettings.apiBaseUrl + '/register', registerModel);
    }

    forgotPassword(forgotPasswordModel: ForgotPasswordModel): Observable<any> {
        return this.http.post(this.environmentSettings.apiBaseUrl + '/forgotpassword', forgotPasswordModel);
    }

    resetPassword(resetPasswordModel: LoginSubmitModel) {
        return this.http.post(this.environmentSettings.apiBaseUrl + '/resetPassword', resetPasswordModel);
    }

    validateResetPasswordToken(userId: string, token: string) {
        return this.http.post(this.environmentSettings.apiBaseUrl + '/validateresetpasswordtoken',
            {}, {params: {'userId': userId, 'token': token}});
    }

    logout() {
        localStorage.removeItem('token');
        this.appState.resetState();
        this.logoutSubject.next(true);
    }

    public isAuthenticated() {
        let token = this.getToken();

        return !(!token || this.isJwtExpired(token));
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    confirmEmail(token: string, userid: string) {
        return this.http.get<any>(this.environmentSettings.apiBaseUrl + `/confirmEmail/?token=${token}&userid=${userid}`);
    }

    createPassword(finalise: FinaliseModel) {
        return this.http.post<any>(this.environmentSettings.apiBaseUrl + '/createPassword', finalise);
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    isOwner(): boolean {
        const idToken = this.getToken();
        if (idToken) {
            const data = jwt_decode(idToken);
            return data && data.Roles && data.Roles.indexOf('Owner') > -1;
        }
        return false;
    }

    isJwtExpired(token: string): boolean {
        const tokenExpirationDate = this.getTokenExpirationDate(token);
        if (!tokenExpirationDate) {
            return true; // Token expiration date not available
        }
        return tokenExpirationDate <= new Date();
    }

    get isTokenRefreshing(): boolean {
        return this._isTokenRefreshing;
    }

    private getTokenExpirationDate(token: string): Date | null {
        try {
            const decodedToken = jwt_decode(token);
            if (!decodedToken || !decodedToken.exp) {
                return null; // Unable to decode token or no expiration time found
            }
            const expirationDate = new Date(0);
            expirationDate.setUTCSeconds(decodedToken.exp);
            return expirationDate;
        } catch (error) {
            return null;
        }
    }
}
